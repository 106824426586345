import {
  EnvelopeFill,
  FileEarmarkTextFill,
  Linkedin,
} from 'react-bootstrap-icons';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';

type TooltipType = {
  id: string;
  children: React.ReactElement;
  tooltip: string;
};

function Profile(): React.ReactElement {
  function TooltipWithLink({
    id,
    children,
    tooltip,
  }: TooltipType): React.ReactElement {
    return (
      <OverlayTrigger
        overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
        placement="right"
      >
        {children}
      </OverlayTrigger>
    );
  }

  return (
    <Container className="profile">
      <Row>
        <Col className="profile-picture">
          <Image
            src={`${process.env.PUBLIC_URL}assets/profile/jerry-profile.jpg`}
            alt="Jerry Profile"
            roundedCircle
          />
        </Col>
      </Row>

      <Row>
        <Col className="profile-social">
          <a href="mailto:jerry.yeoh1270@gmail.com" className="icon email">
            <EnvelopeFill size={42} />
          </a>

          <a
            href="https://www.linkedin.com/in/jerry-yeoh-aa6a872"
            target="_blank"
            className="icon linkedin"
            rel="noreferrer"
          >
            <Linkedin size={35} />
          </a>

          <TooltipWithLink id="resume-tooltip" tooltip="View My Resume">
            <a
              href="assets/files/JY_Resume_0624.pdf"
              target="_blank"
              className="icon phone"
              rel="noreferrer"
            >
              <FileEarmarkTextFill size={35} />
            </a>
          </TooltipWithLink>
        </Col>
      </Row>

      <Row>
        <Col className="profile-description">
          <h2>About Me</h2>
          <p>
            I am a seasoned graphic production artist with a proven track record
            in the Consumer-Packaged Goods industry.
          </p>
          <p>
            Having held key roles in Marketing &amp; Creative Services teams, I
            honed my skills in image retouching, layout, print and digital
            production. I've successfully managed multiple projects from start
            to finish while overseeing production artists, freelancers, vendors,
            and digital asset libraries.
          </p>
          <p>
            Proficient in graphic production with attention to detail and the
            versatility to switch between multiple projects, I bring a
            deadline-driven approach to production management.
          </p>
        </Col>
      </Row>
      <div className="dot-separator"></div>
    </Container>
  );
}

export default Profile;
