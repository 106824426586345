import { SyntheticEvent, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Masonry from 'react-masonry-css';

import useData from '../hooks/useData';

import PortfolioCard from './PortfolioCard';

export type PortfolioItemType = {
  category: string;
  description?: string;
  image: string;
  thumbnail: string;
  subtitle?: string;
};

export type PortfolioFilterItemType = {
  name: string;
  category: string;
};

function Portfolio(): React.ReactElement {
  const portfolio: PortfolioItemType[] = useData('portfolio');
  const filters: PortfolioFilterItemType[] = useData('portfolioFilters');

  const [selectedFilter, setSelectedFilter] = useState('all');
  const handleFilterSelect = (
    event: SyntheticEvent,
    selectedFilter: string,
  ) => {
    event.preventDefault();
    setSelectedFilter(selectedFilter);
  };

  const [filteredPortfolio, setFilteredPortfolio] = useState<
    PortfolioItemType[]
  >([]);
  useEffect(() => {
    if (!selectedFilter || selectedFilter === 'all') {
      setFilteredPortfolio(portfolio);
    } else {
      const filteredPortfolio = portfolio.filter((item) =>
        item.category.includes(selectedFilter),
      );
      setFilteredPortfolio(filteredPortfolio);
    }
  }, [selectedFilter, portfolio]);

  const breakpointColumns = {
    default: 5,
    1200: 3,
    700: 1,
  };

  return (
    <Container className="portfolio">
      <Row>
        <Col>
          <h2>Portfolio</h2>
          {filters && (
            <ul className="portfolio-filter">
              <li
                className={selectedFilter === 'all' ? 'selected' : ''}
                onClick={(event) => handleFilterSelect(event, 'all')}
              >
                All
              </li>
              {filters.map(
                (
                  filter: PortfolioFilterItemType,
                  index: number,
                ): React.ReactElement => (
                  <li
                    key={`filter-${index}`}
                    className={
                      selectedFilter === filter.category ? 'selected' : ''
                    }
                    onClick={(event) =>
                      handleFilterSelect(event, filter.category)
                    }
                  >
                    {filter.name}
                  </li>
                ),
              )}
            </ul>
          )}
        </Col>
      </Row>
      <Masonry
        breakpointCols={breakpointColumns}
        className="portfolio-grid"
        columnClassName="portfolio-grid-column"
      >
        {filteredPortfolio &&
          filteredPortfolio.map((item: PortfolioItemType, index: number) => (
            <div key={`portfolio-item-${index}`}>
              <PortfolioCard
                subtitle={item.subtitle}
                description={item.description}
                thumbnail={item.thumbnail}
                image={item.image}
              />
            </div>
          ))}
      </Masonry>
    </Container>
  );
}

export default Portfolio;
