import type {
  PortfolioFilterItemType,
  PortfolioItemType,
} from './portfolio/Portfolio';

export const portfolio: PortfolioItemType[] = [
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/PackagingFlat_Acai.jpg',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/Packaging_Acai.png',
  },
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/PackagingFlat_Bittersweet-Wafer.jpg',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/Packaging_Bittersweet-Wafer.png',
  },
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/PackagingFlat_Cacao-Bar.jpg',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/Packaging_Cacao-Bar.png',
  },
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/PackagingFlat_Cacao-Caddy.jpg',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/Packaging_Cacao-Caddy.png',
  },
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/PackagingFlat_Costco-Tray.jpg',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/Packaging_Costco-Tray.png',
  },
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/PackagingFlat_Goji.jpg',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/Packaging_Goji.png',
  },
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/PackagingFlat_Matcha.jpg',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/Packaging_Matcha.png',
  },
  {
    category: 'pos',
    image: 'assets/portfolio/pos/POS_Instore-Poster_1.png',
    thumbnail: 'assets/portfolio/pos/POS_Instore-Poster_1.png',
  },
  {
    category: 'pos',
    image: 'assets/portfolio/pos/POS_Instore-Poster_2.png',
    thumbnail: 'assets/portfolio/pos/POS_Instore-Poster_2.png',
  },
  {
    category: 'pos',
    image: 'assets/portfolio/pos/POS_Instore-Poster_3.png',
    thumbnail: 'assets/portfolio/pos/POS_Instore-Poster_3.png',
  },
  {
    category: 'digital',
    image: 'assets/portfolio/digital/Instacart_Shoppable_Ad_Acai_1600x900.jpg',
    thumbnail:
      'assets/portfolio/digital/Instacart_Shoppable_Ad_Acai_1600x900.jpg',
  },
  {
    category: 'digital',
    image: 'assets/portfolio/digital/Instacart_Shoppable_Ad_Cacao_1600x900.jpg',
    thumbnail:
      'assets/portfolio/digital/Instacart_Shoppable_Ad_Cacao_1600x900.jpg',
  },
  {
    category: 'digital',
    image: 'assets/portfolio/digital/Instacart_Shoppable_Ad_Maca_1600x900.jpg',
    thumbnail:
      'assets/portfolio/digital/Instacart_Shoppable_Ad_Maca_1600x900.jpg',
  },
  {
    category: 'digital',
    image:
      'assets/portfolio/digital/Instacart_Shoppable_Banner_3200x800_Cacao.jpg',
    thumbnail:
      'assets/portfolio/digital/Instacart_Shoppable_Banner_3200x800_Cacao.jpg',
  },
  {
    category: 'digital',
    image:
      'assets/portfolio/digital/Instacart_Shoppable_Banner_3200x800_Goji.jpg',
    thumbnail:
      'assets/portfolio/digital/Instacart_Shoppable_Banner_3200x800_Goji.jpg',
  },
  {
    category: 'digital',
    image:
      'assets/portfolio/digital/Instacart_Shoppable_Banner_3200x800_Maca.jpg',
    thumbnail:
      'assets/portfolio/digital/Instacart_Shoppable_Banner_3200x800_Maca.jpg',
  },
  {
    category: 'print',
    description: 'Expo West Booth',
    image: 'assets/portfolio/print/Print_EXPO-West.png',
    thumbnail: 'assets/portfolio/print/Print_EXPO-West.png',
  },
  {
    category: 'print',
    image: 'assets/portfolio/print/Print_Tradeshow-Note-Card-Full.png',
    thumbnail: 'assets/portfolio/print/Print_Tradeshow-Note-Card-Front.png',
  },
  {
    category: 'print',
    description: 'Expo West Standee',
    image: 'assets/portfolio/print/Print_Tradeshow-Standee_30x71.png',
    thumbnail: 'assets/portfolio/print/Print_Tradeshow-Standee_30x71.png',
  },
  {
    category: 'print',
    image: 'assets/portfolio/print/Print_onClick_TrailMix.jpg',
    thumbnail: 'assets/portfolio/print/Print_TrailMix-Sell-Sheet-Front.png',
  },
  {
    category: 'print',
    image: 'assets/portfolio/print/Print_onClick_Lattes.jpg',
    thumbnail: 'assets/portfolio/print/Print_Lattes-3.1oz-Sell-Sheet-Front.png',
  },
  {
    category: 'print',
    image: 'assets/portfolio/print/Print_onClick_RecipeCard.jpg',
    thumbnail: 'assets/portfolio/print/Print_RecipeCard-Front.png',
  },
  {
    category: 'print',
    image: 'assets/portfolio/print/Print_Swag_Cap.png',
    thumbnail: 'assets/portfolio/print/Print_Swag_Cap.png',
  },
  {
    category: 'print',
    image: 'assets/portfolio/print/Print_Swag_Hoodie.png',
    thumbnail: 'assets/portfolio/print/Print_Swag_Hoodie.png',
  },
  {
    category: 'print',
    image: 'assets/portfolio/print/Print_Swag_Tshirt.png',
    thumbnail: 'assets/portfolio/print/Print_Swag_Tshirt.png',
  },
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/us_paella_bowl_flat.png',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/us_paella_bowl_3d.jpg',
  },
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/amys_sonoma_burger_flat.jpg',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/amys_sonoma_burger_3d.png',
  },
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/amys_broc_cheddar_flat.jpg',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/amys_broc_cheddar_3d.png',
  },
  {
    category: 'packaging',
    image: 'assets/portfolio/packaging/amys_chinese_noodle_flat.jpg',
    subtitle: 'Click to view flats',
    thumbnail: 'assets/portfolio/packaging/amys_chinese_noodle_3d.png',
  },
  {
    category: 'ads',
    thumbnail: 'assets/portfolio/ads/amys_ocado_uk_ad.jpg',
    image: 'assets/portfolio/ads/amys_ocado_uk_ad.jpg',
  },
  {
    category: 'ads',
    thumbnail: 'assets/portfolio/ads/amys_gfm_ad.jpg',
    image: 'assets/portfolio/ads/amys_gfm_ad.jpg',
  },
  {
    category: 'print',
    thumbnail: 'assets/portfolio/print/amys_meals_sellsheet.jpg',
    image: 'assets/portfolio/print/amys_meals_sellsheet.jpg',
  },
  {
    category: 'ads',
    thumbnail: 'assets/portfolio/ads/birdseye_dko-ads.jpg',
    image: 'assets/portfolio/ads/birdseye_dko-ads.jpg',
  },
  {
    category: 'ads',
    thumbnail: 'assets/portfolio/ads/coke_game-ads.jpg',
    image: 'assets/portfolio/ads/coke_game-ads.jpg',
  },
  {
    category: 'ads',
    thumbnail: 'assets/portfolio/ads/orville_coke-ads.jpg',
    image: 'assets/portfolio/ads/orville_coke-ads.jpg',
  },
  {
    category: 'pos',
    thumbnail: 'assets/portfolio/pos/wholefoods_freezer_blade.jpg',
    image: 'assets/portfolio/pos/wholefoods_freezer_blade.jpg',
  },
  {
    category: 'pos',
    thumbnail: 'assets/portfolio/pos/us_5vip_coupon_front.png',
    image: 'assets/portfolio/pos/us_5vip_coupon_back.png',
  },
  {
    category: 'pos',
    thumbnail: 'assets/portfolio/pos/us_10vip_coupon_front.png',
    image: 'assets/portfolio/pos/us_10vip_coupon_back.png',
  },
  {
    category: 'pos',
    thumbnail: 'assets/portfolio/pos/lawry_bbq-pos.png',
    image: 'assets/portfolio/pos/lawry_bbq-pos.png',
  },
  {
    category: 'pos',
    thumbnail: 'assets/portfolio/pos/stouffer_coke-pos.jpg',
    image: 'assets/portfolio/pos/stouffer_coke-pos.jpg',
  },
  {
    category: 'display',
    thumbnail: 'assets/portfolio/display/woolworth_soup_display_comp.png',
    image: 'assets/portfolio/display/woolworth_soup_display_mockup.jpg',
  },
  {
    category: 'display',
    thumbnail: 'assets/portfolio/display/amys_candy_display_shipper.jpg',
    image: 'assets/portfolio/display/amys_candy_display_shipper.jpg',
  },
  {
    category: 'display',
    thumbnail: 'assets/portfolio/display/cheezit_coke-display.png',
    image: 'assets/portfolio/display/cheezit_coke-display.png',
  },
  {
    category: 'display',
    thumbnail: 'assets/portfolio/display/emerald_coke-display.png',
    image: 'assets/portfolio/display/emerald_coke-display.png',
  },
  {
    category: 'display',
    thumbnail: 'assets/portfolio/display/lawrys_chips-display.png',
    image: 'assets/portfolio/display/lawrys_chips-display.png',
  },
  {
    category: 'display',
    thumbnail: 'assets/portfolio/display/orville_coke-display.png',
    image: 'assets/portfolio/display/orville_coke-display.png',
  },
  {
    category: 'print',
    thumbnail: 'assets/portfolio/print/us_c-store_snacks_sellsheet.png',
    image: 'assets/portfolio/print/us_c-store_snacks_sellsheet.png',
  },
  {
    category: 'print',
    thumbnail: 'assets/portfolio/print/candy_shipper_instruction.png',
    image: 'assets/portfolio/print/candy_shipper_instruction.png',
  },
  {
    category: 'print',
    thumbnail: 'assets/portfolio/print/print-coke1.jpg',
    image: 'assets/portfolio/print/print-coke1.jpg',
  },
  {
    category: 'print',
    description:
      '3-foot high poster series for an Epiphany Center Dinner/Fundraiser to help raise awareness and funding.',
    thumbnail: 'assets/portfolio/print/print-posters6.jpg',
    image: 'assets/portfolio/print/print-posters6.jpg',
  },
];

const portfolioFilters: PortfolioFilterItemType[] = [
  {
    category: 'packaging',
    name: 'Packaging',
  },
  {
    category: 'ads',
    name: 'Ads',
  },
  {
    category: 'pos',
    name: 'Point-of-Sale',
  },
  {
    category: 'display',
    name: 'Displays',
  },
  {
    category: 'digital',
    name: 'Digital',
  },
  {
    category: 'print',
    name: 'Print',
  },
];

const data = {
  portfolio,
  portfolioFilters,
};

export default data;
