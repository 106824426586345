import { useState } from 'react';

import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

type PropsType = {
  subtitle?: string;
  description?: string;
  thumbnail?: string;
  image: string;
};

type ModalPropsType = {
  description?: string;
  image?: string;
  show: boolean;
  onHide: () => void;
};

function PortfolioModal(props: ModalPropsType) {
  const { description, image } = props;

  return (
    <Modal
      {...props}
      dialogClassName="modal-container"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="img-holder">
          <Image src={`${process.env.PUBLIC_URL}${image}`} fluid />
        </div>
        {description && <p>{description}</p>}
      </Modal.Body>
    </Modal>
  );
}

function PortfolioCard({
  subtitle,
  description,
  thumbnail,
  image,
}: PropsType): React.ReactElement {
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <Card className="portfolio-card" onClick={() => setModalShow(true)}>
        <Card.Img variant="top" src={`${process.env.PUBLIC_URL}${thumbnail}`} />
        {(subtitle || description) && (
          <Card.Body>
            {subtitle && (
              <>
                {subtitle}
                <br />
              </>
            )}
            {description}
          </Card.Body>
        )}
      </Card>

      <PortfolioModal
        description={description}
        image={image}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default PortfolioCard;
